import smoothscroll from 'smoothscroll-polyfill';
import './src/fonts/fonts.css';
import './src/pages/grid.min.css';

smoothscroll.polyfill();

/* Polyfill for ie >= 9 - Element.closest & Element.matches */

if (Element && !Element.prototype.matches) {

  Element.prototype.matches = Element.prototype.msMatchesSelector
    || Element.prototype.webkitMatchesSelector; // jshint ignore:line

}

if (Element && !Element.prototype.closest) {

  Element.prototype.closest = function (s) {

    let el = this;

    do {

      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;

    } while (el !== null && el.nodeType === 1);
    return null;

  };

}
